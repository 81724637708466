<template>
    <div class="answer-content">
      <div class="main-wrapper-header">
        <div class="header-left">
          <el-breadcrumb separator=">" style="margin-left: 4px" >
            <el-breadcrumb-item>训练中心</el-breadcrumb-item>
            <el-breadcrumb-item>实操训练</el-breadcrumb-item>
            <el-breadcrumb-item>{{$route.query.trainName}}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="header-right">
          <el-button @click="toBack" type="primary">返回</el-button>
        </div>
      </div>
        <el-table :data="answerTable" style="width: 100%; flex: 1;margin-top: 20px" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#eee',fontSize: '16px',height:'60px'}"
                  class="customTable" :cell-style="{fontSize: '16px',color: '#333',height: '70px'}">
            <el-table-column prop="num" label="训练次数"></el-table-column>
            <el-table-column prop="score" label="分值 "></el-table-column>
            <el-table-column label="操作" align="center" width="120px">
                <template slot-scope="scope">
                    <div class="link-list">
                        <el-link type="success" :underline="false" @click="viewDetail(scope.row)">查看详情</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center"
                :current-page="answerPages.currentPageNum"
                :page-size="answerPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="answerPages.total"
                @current-change="answerCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
import {stuOpOpTrainNumList} from '@/utils/apis'
export default {
name: "AnswerDetail",
    data(){
        return{
            answerTable:[],
            //分页
            answerPages: {
                currentPageNum: 1,
                eachPageNum: 10,
                total: 0
            },
          moduleId:Number(this.$route.query.moduleId) || null,
        }
    },
    mounted() {
      this.getExamStuDrillKefuDetail()
    },
  methods:{
        getExamStuDrillKefuDetail(){
          let params = {
            op_id:this.$route.query.id,
            course_id:this.$route.query.course_id,
            page:this.answerPages.currentPageNum,
            limit:this.answerPages.eachPageNum
          }
          stuOpOpTrainNumList(params).then((res)=>{
            this.answerTable = res.data.list;
            this.answerPages.total = res.data.count
          }).catch((err)=>{
            console.log('err',err)
          })
        },
        goBack() {
            this.$router.go(-1);
        },
        //查看详情
        viewDetail(row){
          if(this.moduleId === 21 || this.moduleId === 43){
            this.$router.push({
              path: '/student/trainCenter/answerDetailService',
              query:{
                moduleId:this.moduleId,
                trainId:row.id,
                // examId:63
              }
            })
          } else if (this.moduleId === 8 || this.moduleId === 9 || this.moduleId === 10) {
            //商品信息采集与处理
              this.$router.push({
                path: '/student/trainCenter/goodsInfo/lookDetail',
                query:{
                  moduleId:this.moduleId,
                  id:row.id//训练id
                }
              })

          } else if(this.moduleId === 15 ||this.moduleId === 16 || this.moduleId === 18){
            // 网店管理
              this.$router.push({
                path: '/student/trainCenter/lookDetail',
                query:{
                    moduleId:this.moduleId,
                    id:row.id//训练id
                }
              })

          } else if(this.moduleId === 22 || this.moduleId === 23){
            // 数据收集与清洗
              this.$router.push({
                  path: '/student/trainCenter/eCommerceCollectCleanDetail',
                  query: {
                      opId:this.$route.query.id,
                      moduleId:this.moduleId,
                      trainId:row.id,
                      method: 'stuTrain',
                  }
              })

          } else if(this.moduleId === 11 || this.moduleId === 12 || this.moduleId === 13 || this.moduleId === 14){
            // 网店装修
            this.$router.push({
              path:'/student/trainCenter/onlineStoreDetail',
              query:{
                opId:this.$route.query.id,
                moduleId:this.moduleId,
                trainId:row.id,
                trainName:this.$route.query.trainName,
                num:row.num
              }
            })
          } else if(this.moduleId === 19 || this.moduleId === 20){
            // 网络推广
              this.$router.push({
                  path:'/student/trainCenter/networkDetail',
                  query:{
                      opId:this.$route.query.id,
                      moduleId:this.moduleId,
                      train_id:row.id
                  }
              })
          } else if(this.moduleId === 42 || this.moduleId === 43){
              // 网络推广
              this.$router.push({
                  path:'/student/trainCenter/cyberCustomerDetail',
                  query:{
                      opId:this.$route.query.id,
                      moduleId:this.moduleId,
                      train_id:row.id,
                      course_id:this.$route.query.course_id,
                      chapter:this.$route.query.chapter,
                      node:this.$route.query.node,
                  }
              })
          } else if(this.moduleId === 30 || this.moduleId === 31 || this.moduleId === 32){
              // 网店视觉设计
              this.$router.push({
                  path:'/student/trainCenter/onlineShopVisualDesign/lookDetail',
                  query:{
                      moduleId:this.moduleId,
                      id:row.id//训练id
                  }
              })
          }else if (this.moduleId === 39 || this.moduleId === 40 || this.moduleId === 41){
              //网上交易管理
              this.$router.push({
                  path:'/student/trainCenter/onlineTradeManage/lookDetail',
                  query:{
                      moduleId:this.moduleId,
                      id:row.id,//训练id
                      method: 'stuTrain',
                  }
              })
          } else if (this.moduleId === 33 || this.moduleId === 34 || this.moduleId === 35 || this.moduleId === 36 || this.moduleId === 37 || this.moduleId === 38){
              //网络营销
              this.$router.push({
                  path:'/student/trainCenter/cyberMarketDetail',
                  query:{
                      opId:this.$route.query.id,
                      moduleId:this.moduleId,
                      trainId:row.id,
                      method: 'stuTrain',
                  }
              })
          } else if (this.moduleId === 44 || this.moduleId === 45 || this.moduleId === 46){
              // 三级电子商务数据分析与应用
              this.$router.push({
                  path: '/student/trainCenter/eCommerceAnalysisApplicationDetail',
                  query: {
                      opId:this.$route.query.id,
                      moduleId:this.moduleId,
                      trainId:row.id,
                      method: 'stuTrain',
                  }
              })

          }
            // this.$router.push({
            //   path: '/student/trainCenter/lookDetail',
            //   query:{
            //     moduleId:this.moduleId,
            //     id:this.$route.query.id,
            //     // examId:63
            //   }
            // })
        },
        //切换分页
        answerCurrentChange(val){
            this.answerPages.currentPageNum = val;
            this.getExamStuDrillKefuDetail()
        },
        toBack(){
          this.$router.go(-1)
        },
      // 将数字转为大写
      toChinesNum(num){
        let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']; //changeNum[0] = "零"
        let unit = ["", "十", "百", "千", "万"];
        num = parseInt(num);
        let getWan = (temp) => {
          let strArr = temp.toString().split("").reverse();
          let newNum = "";
          for (let i = 0; i < strArr.length; i++) {
            newNum = (i == 0 && strArr[i] == 0 ? "" : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? "" : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum;
          }
          return newNum;
        }
        let overWan = Math.floor(num / 10000);
        let noWan = num % 10000;
        if (noWan.toString().length < 4) noWan = "0" + noWan;
        return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
      }
    }
}
</script>

<style scoped lang="scss">
::v-deep .el-breadcrumb{
  .el-breadcrumb__item{
    .el-breadcrumb__inner{
      color: #999999;
    }
    &:last-of-type{
      .el-breadcrumb__inner{
        color: #333;
      }
    }
  }
  .el-breadcrumb__separator{
    color: #999;
    font-weight: 500;
  }
}
    .answer-content{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 20px;
        .main-wrapper-header{
          padding: 0px 0 14px;
          display: flex;
          justify-content: space-between;
          border-bottom: 2px solid #F1F5FF;
          .header-left{
            display: flex;
            align-items: center;
            span{
              line-height: 1;
            }
          }
        }
    }
</style>